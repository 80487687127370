import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  ActivityIndicator,
  Image,
  TouchableOpacity,
  Button,
} from "react-native";
import axios from "axios";
import { hostname } from "../../../../RN-mobile/backendconnect/hostname";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { fetchFriends } from "../../../utils/FetchFriendList";
import { CheckBox } from "react-native-elements";


function NetworkScreen() {
  const [participants, setParticipants] = useState([]);
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("all");
  const navigation = useNavigation();

  const fetchFriendList = async () => {
    const userToken = await AsyncStorage.getItem("userToken");
    const userString = await AsyncStorage.getItem("user");
    const user = JSON.parse(userString);

    if (userToken && user._id) {
      try {
        const friendsData = await fetchFriends(userToken, user._id);
        setFriends(friendsData.map((friend) => friend._id));
      } catch (error) {
        console.error("Erreur lors de la récupération des amis :", error);
      }
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      await fetchFriendList();
      await fetchParticipants();
    };

    fetchAllData();
  }, []);

  const fetchParticipants = async () => {
    try {
      const userToken = await AsyncStorage.getItem("userToken");
      const userString = await AsyncStorage.getItem("user");
      const user = JSON.parse(userString);

      if (!userToken || !user?._id) {
        setError(
          "Vous devez vous connecter pour voir la liste des participants."
        );
        setLoading(false);
        return;
      }

      const userId = user._id;

      const response = await axios.get(
        `${hostname}/api/v1/user/${userId}/activities`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const allActivities = response.data;
      if (!allActivities || allActivities.length === 0) {
        setError("Aucune activité trouvée pour cet utilisateur.");
        setLoading(false);
        return;
      }

      let allAttendees = allActivities
        .map((activity) => {
          if (
            activity.attendees &&
            activity.attendees.length > 0 &&
            typeof activity.attendees[0] === "object"
          ) {
            return activity.attendees
              .map((att) => att._id.toString())
              .filter((id) => id !== userId);
          }
          return activity.attendees;
        })
        .flat();

      // Ajout des amis à la liste des participants
      const combinedList = [...allAttendees, ...friends];
      const uniqueAttendees = [...new Set(combinedList)];

      const attendeeInfos = await Promise.all(
        uniqueAttendees.map(async (attendeeId) => {
          console.log(`Fetching info for attendee: ${attendeeId}`);
          const {
            data: { user },
          } = await axios.get(
            `${hostname}/api/v1/user/getuserinfo/${attendeeId}`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          return user;
        })
      );

      setParticipants(attendeeInfos);
    } catch (err) {
      console.error("Erreur lors de la récupération des participants :", err);
    } finally {
      setLoading(false);
    }
  };

  const ParticipantItem = ({ item }) => {
    const isFriend = friends.includes(item._id);

    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        {item.avatar ? (
          <Image
            source={{ uri: item.avatar }}
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              marginLeft: 20,
              marginRight: 10,
              borderWidth: 2,
              borderColor: isFriend ? "blue" : "#DAE08D",
            }}
          />
        ) : (
          <View
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              //backgroundColor: "gray",
              marginRight: 10,
              borderWidth: 2,
              borderColor: isFriend ? "blue" : "#DAE08D",
            }}
          />
        )}
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: "bold" }}>
            {item.firstName?.toUpperCase()}{" "}
            {item.lastName?.charAt(0)?.toUpperCase() +
              item.lastName?.slice(1).toLowerCase()}
          </Text>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text>📍</Text>
            <Text>
              {item.city}, {item.postalCode}
            </Text>
          </View>
        </View>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Profile", { user: item });
          }}
        >
          <Image
            source={require("../../assets/images/next2.svg")}
            style={{
              width: 21,
              height: 21,
              marginLeft: 10,
              marginRight: 20,
            }}
          />
        </TouchableOpacity>
      </View>
    );
  };
  const filterParticipants = () => {
    if (filter === "friends") {
      return participants.filter((p) => friends.includes(p._id));
    } else if (filter === "met") {
      return participants.filter((p) => !friends.includes(p._id));
    }
    return participants;
  };

  if (loading) return <ActivityIndicator size="large" color="#0000ff" />;
  if (error) return <Text>Error: {error}</Text>;

  return (
    <View style={{ flex: 1, paddingTop: 20 }}>
      {/* <View
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom: 20,
        }}
      >
        <CheckBox
          title="= All people"
          checkedIcon="dot-circle-o"
          uncheckedIcon="circle-o"
          //checkedColor="yellow"
          checked={filter === "all"}
          onPress={() => setFilter("all")}
          containerStyle={{ backgroundColor: "transparent", borderWidth: 0 }} // Ajoutez cette ligne
          textStyle={{ color: "black" }} // Si vous souhaitez une couleur de texte spécifique
        />

        <CheckBox
          title="= Friends"
          checkedIcon="dot-circle-o"
          uncheckedIcon="circle-o"
          checkedColor="#65B3EA" // couleur lorsqu'il est sélectionné
          uncheckedColor="#65B3EA" // couleur lorsqu'il n'est pas sélectionné
          checked={filter === "friends"}
          onPress={() => setFilter("friends")}
          containerStyle={{ backgroundColor: "transparent", borderWidth: 0 }}
          textStyle={{ color: "black" }}
        />

        <CheckBox
          title="= People i met"
          checkedIcon="dot-circle-o"
          uncheckedIcon="circle-o"
          checkedColor="#DAE08D" // couleur lorsqu'il est sélectionné
          uncheckedColor="#DAE08D" // couleur lorsqu'il n'est pas sélectionné
          checked={filter === "met"}
          onPress={() => setFilter("met")}
          containerStyle={{ backgroundColor: "transparent", borderWidth: 0 }} // Ajoutez cette ligne
          textStyle={{ color: "black" }} // Si vous souhaitez une couleur de texte spécifique
        />
      </View> */}
      <FlatList
        data={filterParticipants()}
        keyExtractor={(item) => item._id}
        renderItem={({ item }) => <ParticipantItem item={item} />}
        ItemSeparatorComponent={() => (
          <View
            style={{ height: 1, backgroundColor: "black", marginVertical: 5 }}
          />
        )}
      />
    </View>
  );
}

export default NetworkScreen;