//🇫🇷 Page d'inscription (Figma Frame 4)
//🇬🇧 Register Page (Figma Frame 4)

import { useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import styles from "./Styles/RegisterScreenCss";
import axios from "axios";
import Json from "../../../RN-mobile/assets/json/en.json";
import Fields from "../components/Fields";
import en from "../../../RN-mobile/assets/json/en.json";
import { useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { hostname } from "../../../RN-mobile/backendconnect/hostname.js";
import { useRoute } from "@react-navigation/native";

const RegisterScreen = ({
  route,
  navigation,
  setToken,
  setProfile,
  setUserProfile,
  setNumber,
  setRegisterMailCall,
  scr,
  //setRole,
  //rolesList
}) => {
  const routeemail = route.params.email;
  console.log(useRoute().params);
  const [email, setEmail] = useState(routeemail);
  const [password, setPassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [pressed, setPressed] = useState(false); //🇫🇷 Variable permettant de vérifier si le bouton a été pressé
  const [errorMsg, setErrorMsg] = useState(null); //🇫🇷 Variable permettant d'afficher les messages d'erreur
  const [emptyFields, setEmptyFields] = useState(null); //🇫🇷 Variable permettant d'afficher les messages d'erreur
  //🇫🇷 Vérifier les erreurs d'email et mot de passe dans le champ
  //🇬🇧 Checking email and password as you type
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [btnDisable, setBtnDisable] = useState(true); //🇫🇷 Variable permettant de désactiver le bouton
  const [backgroundColor, setBackgroundColor] = useState("");
  const [errorTimer, setErrorTimer] = useState(null);
  const { register } = scr;
  const langue = useSelector((state) => state.langue); //🇫🇷 Passsage de langue depuis reduceur🇫🇷
  //🇬🇧 Language passed from reducer

  const isValidPassword = (password) => {
    // Vous pouvez ajouter d'autres règles de validation ici
    return password && password.length >= 8;
  };

  useEffect(() => {
    if (!isValidPassword(password)) {
      setBackgroundColor("grey");
      setBtnDisable(false);
    } else {
      setBtnDisable(false);
      setBackgroundColor("#59c09b");
    }
  }, [password]);

  // Whenever errorMsg changes, start a timer to clear it after 2 seconds
  useEffect(() => {
    if (errorMsg) {
      const timer = setTimeout(() => {
        setErrorMsg(null);
      }, 5000); // 2000 milliseconds = 2 seconds

      setErrorTimer(timer); // Save the timer reference so we can clear it if needed
    }

    // Clear the timer if the component is unmounted or if errorMsg changes
    return () => {
      if (errorTimer) {
        clearTimeout(errorTimer);
      }
    };
  }, [errorMsg]);

  //🇫🇷 Envoi de la requête pour s'inscrire.
  //🇬🇧 Sending async request for signing up.
  const requestRegister = async () => {
    setProfile(null); //🇫🇷 On remet le profil à null pour éviter les bugs
    console.log("i'm here");

    let same = true;
    setErrorMsg(null); //🇫🇷 On remet le message d'erreur à null pour éviter les bugs
    {
      /*🇫🇷 Vérifier que l'email existe*/
    }
    {
      /*🇬🇧 Checking if email exists*/
    }
    // Check if password field is empty
    if (!password) {
      setErrorMsg(langue?.register.emptyPasswordError); // You can replace the error message with the appropriate one in your language file
      return; // Exit the function to prevent further execution
    }

    if (password) {
      //🇫🇷 Vérifier que le mot de passe existe
      try {
        const response = await axios.post(`${hostname}/api/v1/signup`, {
          email: email,
          password: password,
        });
        if (response) {
          //🇫🇷 Si la réponse est positive, on passe à la page de vérification du mail
          console.log("ffff", response.data, response.data.user.token);
          setNumber(1);
          //setRole(nonVerifiedUser);
          setToken(response.data.user.token);
          console.log(response.data.user);
        }
      } catch (error) {
        //🇫🇷 Si la réponse est négative, on affiche le message d'erreur
        if (error.response.status === 400) {
          console.log("monErreur:", error.response.data.error);
          if (
            error.response.data.error.errors &&
            error.response.data.error.errors.length > 0
          ) {
            const serverErrorMsg = error.response.data.error.errors[0].msg;
            if (
              serverErrorMsg ===
              "password field should be at least 8 characters"
            ) {
              setErrorMsg(langue?.register?.passwordError);
            } else {
              // Handle other error messages or display the server error directly
              setErrorMsg(serverErrorMsg);
            }
          } else {
            setErrorMsg(JSON.stringify(error.response.data.error));
          }
        } else if (error.response.status === 401) {
          if (typeof error.response.data.error === "string") {
            setErrorMsg(error.response.data.error);
          } else if (
            error.response.data.error.errors &&
            error.response.data.error.errors.length > 0
          ) {
            // Extraire le message d'erreur du premier élément de l'array "errors"
            setErrorMsg(error.response.data.error.errors[0].msg);
          } else {
            // Convertir l'objet en chaîne ou afficher un message d'erreur générique
            setErrorMsg(JSON.stringify(error.response.data.error));
          }
        }
      }
    }
  };
  return (
    <ScrollView>
      <View style={styles.password}>
        {errorMsg && (
          <View style={[styles.center, { marginBottom: 10 }]}>
            <Text style={styles.error}>{errorMsg}</Text>
          </View>
        )}
        {/*🇫🇷 La variable register.password dans fr.json permet d'afficher "Mot de passe"*/}
        {/*🇬🇧 The en.json variable register.password displays "Password""*/}
        <Fields
          onSubmitEditing={requestRegister}
          text={langue?.register?.password}
          icon={"locker"}
          state={password}
          setState={setPassword}
          pressed={pressed}
          setPressed={setPressed}
          errorPass={errorPassword}
        />
      </View>

      <View style={styles.btn}>
        <TouchableOpacity
          style={[styles.bigBtn, { backgroundColor: backgroundColor }]}
          disabled={btnDisable}
          onPress={() => {
            setPressed(true);
            requestRegister();
            setBtnDisable(false);
            //🇬🇧 Here we set the number to 1 to access the verificationMail screen by validating
            //🇫🇷 Nous avons mis le setNumber à 1 pour accéder à la vérification du Mail en cliquant sur s'enregistrer
            setNumber(1);
            //🇫🇷 Recupérer les informations du mail en les passant dans les props
            //🇬🇧 To keep the e-mail information by passing it into props
            setRegisterMailCall(email);
          }}
        >
          <Text style={styles.btnText}>
            {/*🇫🇷 La variable register.button_1 dans fr.json permet d'afficher "S'inscrire"*/}
            {/*🇬🇧 The en.json variable register.button_1 displays "Register"*/}
            {langue?.register?.button_1}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.centerTerms}>
        <View style={styles.termsContainer}>
          <Text style={styles.terms}>{langue?.register?.jsondisclaimer}</Text>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL(
                "https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An"
              )
            }
          >
            <Text style={styles.underline}>
              {langue?.register?.jsontermsandconditions}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL(
                "https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An"
              )
            }
          >
            <Text style={styles.underline}>
              {langue?.register?.jsonprivacypolicy}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.centerBot}>
        <View style={styles.bottomBoxTop}>
          <Text style={styles.bottomText}>
            {/*🇫🇷 La variable register.label_6 dans fr.json permet d'afficher "Tu as oublié ton mot de passe ?"*/}
            {/*🇬🇧 The en.json variable register.label_6 displays "Forgot your password?"*/}
            {langue?.register?.label_6}
          </Text>

          <TouchableOpacity
            onPress={() => {
              navigation.navigate("LogIn", {
                MsgE: langue?.emailscreen?.update_tittle_reset,
              });
            }}
            style={styles.smallBtn}
          >
            <Text style={styles.smallBtnText}>
              {/*🇫🇷 La variable register.label_7 dans fr.json permet d'afficher "Mot de passe oublié""*/}
              {/*🇬🇧 The en.json variable register.label_7 displays "Forgotten password"*/}
              {langue?.register?.label_7}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
};

export default RegisterScreen;
