import { hostname } from "../../RN-mobile/backendconnect/hostname";



export const fetchFriends = async (userToken, userId) => {
  if (!userToken || !userId) {
    throw new Error("Vous devez vous connecter pour voir la liste des amis.");
  }
  try {
    const response = await fetch(`${hostname}/api/v1/users/${userId}/friends`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch friends");
    }

    const data = await response.json();
    return data.friends;
  } catch (err) {
    throw err;
  }
};