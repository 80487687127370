import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 10,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  countryCard: {
    margin: 10,
    width: 70,
    height: 55,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#59c09b",
    borderWidth: 1,
    borderRadius: 15,
    backgroundColor: "white",
  },
  flagShadow: {
    position: "absolute",
    width: 36,
    height: 24,
    backgroundColor: "#BBBBBB",
    zIndex: -1,
    top: 17,
    left: 2,
  },
  countryCard_severalFlags: {
    marginHorizontal: 4,
    marginVertical: 6,
    width: 77,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#59c09b",
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: "white",
  },
  countryCard_Shadow: {
    marginHorizontal: 4,
    marginVertical: 6,
    width: 77,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#59c09b",
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.42,
    shadowRadius: 2.22,
  },
  // shadow_severalFlags: {
  //   margin: 4,
  //   width: 77,
  //   height: 45,
  //   borderRadius: 10,
  //   backgroundColor: "#CCCCCC",
  //   zIndex: -1,
  //   position: "absolute",
  //   bottom: -3,
  // },
  // flagShadow_severalFlags: {
  //   position: "absolute",
  //   width: 30,
  //   height: 20,
  //   backgroundColor: "#BBBBBB",
  //   zIndex: -1,
  //   top: 15,
  //   left: 2,

  // },
  flagsStyle: {
    flex: 1,
    justifyContent: "center",
    position: "relative",
  },
});

export default styles;
