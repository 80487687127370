import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  profil: {
    backgroundColor: "white",
    flex: 1,
    paddingTop: 60,
    paddingHorizontal: 30,
  },
  topInput: {
    marginTop: 60,
  },
  botInput: {
    marginTop: 25,
  },
  btn: {
    marginTop: 35,
    backgroundColor: "#59c09b",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  text: {
    textAlign: "center",
    margin: 15,
    marginBottom: 0,
  },
});

export default styles;
