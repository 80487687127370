import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  Image,
  TouchableOpacity,
} from "react-native";
import axios from "axios";
import { hostname } from "../../../../RN-mobile/backendconnect/hostname";
import AsyncStorage from "@react-native-async-storage/async-storage";
import calendar from "../../assets/images/calendar.svg";
import calendarOn from "../../assets/images/calendar-on.svg";
import members from "../../assets/images/MembersOn.svg";
import { useNavigation } from "@react-navigation/native";
import { activitiesList } from "../../assets/activityList/activityListWithIcons.js";

function ProfileActivities({ userId }) {
  const navigation = useNavigation();
  const [activities, setActivities] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState("");

  const parseDate = (dateString) => {
    if (dateString.includes("/")) {
        const [day, month, year] = dateString.split("/");
        return new Date(year, month - 1, day);
    }
    else if (dateString.includes("-")) {
        const [year, month, day] = dateString.split("-");
        return new Date(year, month - 1, day);
    }
    throw new Error(`Unrecognized date format: ${dateString}`);
  };

  const fetchActivities = async () => {
    try {
      const userToken = await AsyncStorage.getItem("userToken");
      if (!userToken || !userId) {
        setError("Vous devez vous connecter pour voir la liste des activités.");
        return;
      }

      const response = await axios.get(
        `${hostname}/api/v1/user/${userId}/activities`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const allActivities = response.data;
      console.log("Activités non filtrées :", allActivities);

      if (!allActivities || allActivities.length === 0) {
        setError("Aucune activité trouvée pour cet utilisateur.");
        return;
      }

      const sortedActivities = allActivities.sort(
        (a, b) => parseDate(a.date) - parseDate(b.date)
      );

      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate());
      currentDate.setHours(0, 0, 0, 0);

      const futureActivities = sortedActivities.filter((activity) => {
        const activityDate = parseDate(activity.date);
        return activityDate >= currentDate;
      });

      console.log("Date de comparaison (actuelle + 1 jour) :", currentDate);
      console.log("Activités filtrées :", futureActivities);
      futureActivities.forEach((activity, index) => {
        console.log(`Activité ${index + 1}:`, activity.date);
      });

      setActivities(futureActivities.slice(0, 5));
    } catch (err) {
      setError("Erreur lors de la récupération des activités.");
      console.error(err);
    } finally {
      setLoadingActivities(false);
    }
  };
const formatDate = (dateString) => {
  if (dateString.includes("/")) {
    const [day, month, year] = dateString.split("/");
    return `${day}/${month}/${year.slice(-2)}`;
  } else if (dateString.includes("-")) {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year.slice(-2)}`;
  } else {
    throw new Error(`Unrecognized date format: ${dateString}`);
  }
};

  const fetchUser = async () => {
    try {
      const userToken = await AsyncStorage.getItem("userToken");
      if (!userToken || !userId) {
        setError(
          "Vous devez vous connecter pour voir les informations de l'utilisateur."
        );
        return;
      }

      const response = await axios.get(
        `${hostname}/api/v1/user/getuserinfo/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const userData = response.data.user;
      if (!userData) {
        setError("Aucune information d'utilisateur trouvée.");
        return;
      }

      setFirstName(userData.firstName);
      console.log(
        "Réponse de l'API pour les détails de l'utilisateur:",
        response.data
      );
    } catch (err) {
      setError(
        "Erreur lors de la récupération des informations de l'utilisateur."
      );
      console.error("Erreur lors de la récupération de l'utilisateur:", err);
    }
  };

  useEffect(() => {
    fetchActivities();
    fetchUser();
  }, [userId]);

  const navigateToActivityDetail = (activity) => {
    console.log("Navigating to activity ID:", activity._id);

    // Vérifiez si l'objet activity et toutes ses propriétés nécessaires sont définis
    if (activity && activity._id && activity.address) {
      navigation.navigate("Activity", {
        activity: activity,
      });
    } else {
      console.error(
        "Les données de l'activité sont incomplètes ou manquantes."
      );
    }
  };

  if (loadingActivities) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  return (
    <View style={{ flex: 1, paddingTop: 20, paddingHorizontal: 10 }}>
      <Text style={{ fontSize: 16, fontWeight: "bold", marginBottom: 20 }}>
        Les 5 prochaines activités de {firstName ? firstName : userId}
      </Text>

      <View
        style={{
          flexDirection: "column",
          borderWidth: 1,
          borderColor: "#000", // Couleur des lignes du tableau
          marginBottom: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1, // Ligne de séparation entre l'en-tête et les données
            borderColor: "#000",
            backgroundColor: "#f2f2f2", // Couleur de fond de l'en-tête
          }}
        >
          <Text
            style={{
              ...styles.column,
              width: 100,
              paddingTop: 10,
              background: "#505050",
            }}
          >
            <img src={calendar} />
          </Text>
          <View
            style={{
              ...styles.column,
              flex: 2,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              background: "#797B7A",
            }}
          >
            <Text style={{ marginRight: 5, fontWeight: "bold", color: "#fff" }}>
              Activité
            </Text>
            <img src={calendarOn} />
          </View>
          <Text style={{ ...styles.column, width: 100, background: "#505050" }}>
            <img src={members} />
          </Text>
          <View
            style={{
              ...styles.column,
              width: 100,
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 5,
              paddingRight: 5,
              background: "#505050",
              color: "#fff",
            }}
          >
            Orga
          </View>
        </View>

        {activities.map((activity, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              borderBottomWidth: 1,
              borderColor: "#000",
              backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
            }}
          >
            {/* Afficher la dates futres des activitée */}
            <Text style={{ ...styles.column, width: 100 }}>
              {formatDate(activity.date)}
            </Text>
            {/* Afficherle nom des activitées */}
            <View
              style={{
                ...styles.column,
                flex: 2,
                flexDirection: "row",
                alignItems: "flex-end",
                color: "#59C19C",
                textWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              {/* titre des activitées */}
              <Text>
                {activity.title.length > 17
                  ? `${activity.title}`
                  : activity.title}
              </Text>
              {/* <TouchableOpacity
                onPress={() => navigateToActivityDetail(activity)}
              >
                <Image
                  source={require("../../assets/images/next2.svg")}
                  style={{
                    width: 15,
                    height: 15,
                    marginLeft: 20,
                  }}
                />
              </TouchableOpacity> */}
            </View>
            {/* afficher le nombre de participants */}
            <Text style={{ ...styles.column, width: 100 }}>
              {activity.attendees.length}
            </Text>
            <Text
              style={{
                ...styles.column,
                width: 100,
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {/* Afficherle nom de l'Organisateur */}
              {activity.author.firstName.length > 10
                ? `${activity.author.firstName.substring(0, 10)}...`
                : activity.author.firstName}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}

const styles = {
  column: {
    flex: 1,
    fontWeight: "bold",
    borderRightWidth: 1, // Ajoute une bordure à droite de chaque élément
    borderColor: "#000", // Couleur de la bordure
    textAlign: "center", // Centrer le texte horizontalement
  },
};

export default ProfileActivities;
