//FR Page du profil utilisateur (Frame Figma 70)
//GB User profile screen (Figma Frame 70)

import {
  Text,
  ScrollView,
  Image,
  View,
  SafeAreaView,
  TouchableOpacity,
  Modal,
  TextInput,
  FlatList,
} from "react-native";
import styles from "../Styles/MyProfileScreenCss";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { hostname } from "../../../../RN-mobile/backendconnect/hostname.js";
import { useState, useEffect } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import Json from "../../../../RN-mobile/assets/json/en.json";
import PopupDialog from "./PopupDialog.js";

import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import TopNavigation from "../../navigation/TopNavigation.js";
import { color } from "react-native-reanimated";
import Edit from "../../assets/images/Iconedit.svg";
import ProfileInfo from "./ProfileInfo.js";
import ProfileFriends from "./ProfileFriends.js";
import ProfileActivities from "./ProfileActivities.js";
import { Slider } from "@rneui/base";
import { ProgressBar } from "react-native-paper";
import { Bar } from "react-native-progress";
import AddFriend from "../../assets/profile/add-friend.svg";
import BlockFriend from "../../assets/profile/block-friend.svg";
import Chat from "../../assets/profile/chat.svg";
import Placeholder2 from "../../assets/images/placeholder2.svg";
import warning from "../../assets/images/warning.svg";
import { useWindowDimensions } from "react-native";
import ComingSoon from "../../assets/images/coming-soon.svg";
import React from "react";
import moment from "moment";
import usergirl from "../../assets/images/image_edit_profile/user-girl.svg";
import userguy from "../../assets/images/image_edit_profile/user-guy.svg";
import ProfileNetwork from "./ProfileNetwork.js";
import email from "../../assets/images/image_edit_profile/envelope.svg";
import phone from "../../assets/images/phone.svg";
import birthday from "../../assets/images/birthday.svg";
import users from "../../assets/images/image_edit_profile/avatar.svg";
import close from "../../assets/images/close.svg";
import ArrayRight from "../../assets/images/right-arrow.svg";
import lastName from "../../assets/images/image_edit_profile/textFrame.svg";
import leftArrow from "../../assets/images/left-arrow.svg";
import IntlPhoneInput from "react-native-intl-phone-input";
import { countriesListCircleFlags } from "../../assets/countriesListCircleFlags";
import { countriesListRectFlags } from "../../assets/countriesListRectFlags";
import DropDownPicker from "react-native-dropdown-picker";
import { Picker } from "@react-native-picker/picker";
//Date picker pour ReactNative Pure
//import DateTimePicker from "@react-native-community/datetimepicker";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import SimpleDateField from "../../components/SimpleDtaeField";
import { DateField } from "../../components/CalendarField";
//import useAddFriend from "../../components/useAddFriend";

const TopTab = createMaterialTopTabNavigator(); //
const Stack = createNativeStackNavigator(); //Stack permettant de switcher entre les pages informations, activités et friends de l'utilisateur

const MyProfileScreen = ({ user, scr }) => {
  const [age, setAge] = useState(""); //🇫🇷 Etat pour gerer l'age de l'utilisateur
  // const { friendDetails, addFriend, loading, error } = useAddFriend(
  //   visitedUserToken,
  //   hostname
  // );

  useEffect(() => {
    if (user.birthday) {
      const timestamp = user.birthday;
      const birthday = moment(timestamp);
      setAge(moment().diff(birthday, "years")); // function qui permet le calcule de l'age a partir du timestamp recuperé dans la base de données
    }
  });

  //Page du profil utilisateur (Frame Figma 70)
  const { height, width } = useWindowDimensions(); //🇫🇷 Etat pour gerer la taille de l'image de profil
  // Etat pour gerer le droit de éditer le profil, le token local est comparé avec le token des autres utilsateurs pour afficher le bouton edit profile
  const [userToken, setUserToken] = useState(null);

  //Variable de json permettant de rendre dynamique le texte front-end
  const { profile, editProfile, confirmfriendship, createProfile, activity } =
    scr;

  const navigation = useNavigation();

  const route = useRoute(); //🇫🇷 la recuperation de données utilisateur depuis middleNav pour afficher profil d'utilisateur dans une activité
  user = route.params.user; //🇬🇧 Retrieving user data from middleNav to display user profile in an activity

  const [imageSize, setImageSize] = useState(0);
  const [imageGenre, setImageGenre] = useState();

  console.log("user:", user);
  const visitedUserToken = user.token;
  console.log("canEdit", canEdit);
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmPressed, setConfirmPressed] = useState(false);
  const [cancelPressed, setCancelPressed] = useState(false);
  const [addFriendModalVisible, setAddFriendModalVisible] = useState(false);
  const [emailPressed, setEmailPressed] = useState(false);
  const [lastNamePressed, setLastNamePressed] = useState(false);
  const [phonePressed, setPhonePressed] = useState(false);
  const [birthdayPressed, setBirthdayPressed] = useState(false);
  const [memberNumberPressed, setMemberNumberPressed] = useState(false);
  const [continuePressed, setContinuePressed] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [confirmEmailModalVisible, setConfirmEmailModalVisible] =
    useState(false);
  const [phoneModalVisible, setPhoneModalVisible] = useState(false);
  const [lastNameModalVisible, setLastNameModalVisible] = useState(false);
  const [birthdayModalVisible, setBirthdayModalVisible] = useState(false);
  const [memberNumberModalVisible, setMemberNumberModalVisible] =
    useState(false);
  const [selectedCountry, setSelectedCountry] = useState("FR");
  const [countryCode, setCountryCode] = useState("+33");
  const [phoneNumber, setPhoneNumber] = useState("");

  const onCountryChange = (countryObj) => {
    setSelectedCountry(countryObj.bigram);
    // add the code for country dial code
    setCountryCode(countryObj.dialCode);
  };
  const [date, setDate] = useState(null);

  const [isPhonePublic, setIsPhonePublic] = useState(false);
  const [isLastNamePublic, setIsLastNamePublic] = useState(false);
  const [isBirthdayPublic, setIsBirthdayPublic] = useState(false);

  const [alreadyFriendsModalVisible, setAlreadyFriendsModalVisible] =
    useState(false);

  // Supposons que vous avez déclaré ces états ailleurs
  const [isEmailAvailable, setIsEmailAvailable] = useState(false);
  const [isPhoneAvailable, setIsPhoneAvailable] = useState(false);
  const [isLastNameAvailable, setIsLastNameAvailable] = useState(false);
  const [isBirthdayAvailable, setIsBirthdayAvailable] = useState(false);
  const [isMemberNumberAvailable, setIsMemberNumberAvailable] = useState(false);
  const [popupDialogVisible, setPopupDialogVisible] = useState(false);
  const [popupDialogContent, setPopupDialogContent] = useState("");

  const [isPhoneActive, setPhoneActive] = useState(false);
  const [isInputEmpty, setInputEmpty] = useState(true);

  //llogique pour griser le outton addfreind

  const [alreadyFriend, setAlreadyFriend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchMethod, setSearchMethod] = useState(null);
  const [dateInput, setDateInput] = useState("");

  useEffect(() => {
    //🇫🇷 useEffect pour obtenir token utilisateur et faire verification pour button edit profile
    //🇬🇧 useEffect to get user token and do verification for button edit profile
    Image.getSize(user.avatar, (width, height) => {
      setImageSize(360);
      if (user.sexe === "male") {
        setImageGenre(userguy); //🇫🇷 Condition pour afficher image homme ou femme
      } else {
        setImageGenre(usergirl);
      }
    });
    const fetchToken = async () => {
      //🇫🇷 Récupération des données utilisateur dans le storage
      const receivedUsertoken = await AsyncStorage.getItem("userToken");
      setUserToken(receivedUsertoken); //🇫🇷 Stockage du token utilisateur dans le state
    };
    fetchToken();
  }, []);

  // console.log("user data", user);
  const [city, setCity] = useState(" "); //🇫🇷 Etat pour gerer la ville de l'utilisateur
  useEffect(() => {
    if (user.city && user.city != "null") {
      let userCity = user.city.split(", ");
      setCity(
        userCity[0].charAt(0).toUpperCase() + userCity[0].slice(1).toLowerCase()
      );
    }
  }, []);

  //const canEdit = user.token === userToken;
  var canEdit;
  if (user.token) {
    if (user.token === userToken) {
      //🇫🇷 Condition pour afficher le bouton edit profile
      canEdit = true;
    } else {
      canEdit = false;
    }
  } else {
    canEdit = true;
  }

  useEffect(() => {
    console.log("isPhonePublic a été mis à jour:", isPhonePublic);
  }, [isPhonePublic]);

  useEffect(() => {
    console.log("isLastNamePublic a été mis à jour:", isLastNamePublic);
  }, [isLastNamePublic]);

  useEffect(() => {
    console.log("isBirthdayPublic a été mis à jour:", isBirthdayPublic);
  }, [isBirthdayPublic]);

  const fetchUserDetails = async (userId) => {
    if (!userId) {
      console.error("Invalid userId:", userId);
      return;
    }

    console.log("fetchUserDetails executed with userId:", userId);

    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      console.log("Sending request to server with options:", requestOptions);

      const response = await fetch(
        `${hostname}/api/v1/user/getuserinfo/${userId}`,
        requestOptions
      );

      if (!response.ok) {
        console.error("Server responded with an error:", response.status);
      }

      const data = await response.json();
      console.log("Raw data received from the server:", data);
      if (!data || !data.user) {
        console.error("Invalid data from the server:", data);
        return;
      }

      const user = data.user;
      console.log("Processed user data from the server:", user);

      if (user) {
        setIsPhonePublic(!!user.isPhonePublic);
        setIsLastNamePublic(!!user.isLastNamePublic);
        setIsBirthdayPublic(!!user.isBirthdayPublic);
        setIsEmailAvailable(!!user.email);
        setIsPhoneAvailable(!!user.phone);
        setIsLastNameAvailable(!!user.lastName);
        setIsBirthdayAvailable(!!user.birthday);
        setIsMemberNumberAvailable(!!user.memberId);
      }
      return user;
    } catch (error) {
      console.error("Error retrieving user details:", error);
    }
    console.log("user connecter:", user);
  };

  console.log("user de profile visiter:", user);

  const [profilData, setProfilData] = useState(user);
  const [currentUserId, setCurrentUserId] = useState(user);

  useEffect(() => {
    console.log("useEffect exécuté");
    if (profilData && profilData._id) {
      const userId = profilData._id;
      setCurrentUserId(userId);
      fetchUserDetails(userId);
    }
  }, [profilData]);
  console.log("profilData:", profilData);

  const [loggedInUser, setLoggedInUser] = useState(null);
  //recupuration du user connecter depuis le local storage
  useEffect(() => {
    const userFromLocalStorage = localStorage.getItem("user");
    if (userFromLocalStorage) {
      const parsedUser = JSON.parse(userFromLocalStorage);
      setLoggedInUser(parsedUser._id);
    }
  }, []);

  console.log("user connecter:", loggedInUser);
  console.log("user de profile visiter:", user._id);

  const [friendEmail, setFriendEmail] = useState("");
  const [friendPhone, setFriendPhone] = useState("");
  const [friendLastName, setFriendLastName] = useState("");
  const [friendBirthday, setFriendBirthday] = useState("");
  const [friendMemberNumber, setFriendMemberNumber] = useState("");
  console.log(friendPhone);

  const fetchUrlMap = {
    memberId: (memberId) =>
      `${hostname}/api/v1/user/getuserbymemberid/${memberId}`,
    lastName: (lastName) =>
      `${hostname}/api/v1/user/getuserbylastname/${lastName}`,
    phoneNumber: (phoneNumber) =>
      `${hostname}/api/v1/user/getuserbyphonenumber/${phoneNumber}`,
    birthday: (birthdayString) =>
      `${hostname}/api/v1/user/getuserbybirthday/${dateToTimestamp(
        birthdayString
      )}`,
    email: (email) => `${hostname}/api/v1/user/getuserbyemail/${email}`,
  };

  const errorMessageMap = {
    memberId:
      "Ooops ! Are you a real friend? Please enter the correct information to confirm your friendship.",
    lastName:
      "Ooops ! Are you a real friend? Please enter the correct last name to confirm your friendship.",
    phoneNumber:
      "Ooops ! Are you a real friend? Please enter the correct phone number to confirm your friendship.",
    birthday:
      "Ooops ! Are you a real friend? Please enter the correct birthday to confirm your friendship.",
    email:
      "Ooops ! Are you a real friend? Please enter the correct information to confirm your friendship.",
  };

  const handleAddFriend = async (input, type) => {
    if (!fetchUrlMap[type]) {
      console.error(`Invalid type provided: ${type}`);
      return;
    }

    try {
      const fetchUrl = fetchUrlMap[type](input);
      const visitedUserResponse = await fetch(fetchUrl);
      if (!visitedUserResponse.ok) {
        if (visitedUserResponse.status === 404) {
          throw new Error(errorMessageMap[type]);
        }
        throw new Error(`HTTP error! Status: ${visitedUserResponse.status}`);
      }
      const visitedUser = await visitedUserResponse.json();
      if (!visitedUser._id) {
        throw new Error(errorMessageMap[type]);
      }

      const userInfoResponse = await fetch(
        `${hostname}/api/v1/user/getuserinfo/${visitedUser._id}`
      );
      if (!userInfoResponse.ok) {
        throw new Error(`HTTP error! Status: ${userInfoResponse.status}`);
      }
      const userInfo = await userInfoResponse.json();

      setFriendEmail(userInfo.user.email || "");
      setFriendPhone(userInfo.user.phone || "");
      setFriendLastName(userInfo.user.lastName || "");
      setFriendBirthday(timestampToDate(userInfo.user.birthday) || "");
      setFriendMemberNumber(userInfo.user.memberNumber || "");

      const addFriendResponse = await fetch(
        `${hostname}/api/v1/user/friends/${visitedUser._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "logged-in-user-id": loggedInUser,
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!addFriendResponse.ok) {
        switch (addFriendResponse.status) {
          case 409:
            setPopupDialogContent("Vous êtes déjà amis.");
            break;
          case 400:
            throw new Error("Impossible d'ajouter l'ami : Mauvaise requête.");
            break;
          default:
            throw new Error("Impossible d'ajouter l'ami");
        }
      } else {
        const addFriendResult = await addFriendResponse.json();
        if (addFriendResult && addFriendResult.updatedFriendsList) {
          const currentUserData = JSON.parse(localStorage.getItem("user"));
          const updatedUserData = {
            ...currentUserData,
            friends: addFriendResult.updatedFriendsList,
          };
          localStorage.setItem("user", JSON.stringify(updatedUserData));
        }
        setPopupDialogContent("Félicitations ! Vous êtes maintenant amis.");
        setAlreadyFriend(true);
      }
    } catch (error) {
      console.log(error);
      setPopupDialogContent(error.message || "Une erreur est survenue");
    } finally {
      setPopupDialogVisible(true);
    }
  };

  function dateToTimestamp(dateString) {
    const [day, month, year] = dateString.split("/");
    const date = new Date(year, month - 1, day);
    return date.getTime();
  }

  function timestampToDate(timestamp) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  console.log(dateToTimestamp("27/03/1981"));

  //console.log(convertToTimestamp("27/03/1981"));

  const checkIfAlreadyFriend = () => {
    //const user = JSON.parse(localStorage.getItem("user"));
    const loggedInUser = JSON.parse(localStorage.getItem("user"));

    const isFriend = loggedInUser?.friends?.includes(currentUserId);

    setAlreadyFriend(isFriend);
  };

  //console.log("alreadyFriend:", alreadyFriend);
  const handlePress = async () => {
    setIsLoading(true);

    try {
      if (!alreadyFriend) {
        setAddFriendModalVisible(true);
        fetchUserDetails(currentUserId);
        await handleAddFriend();
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout d'ami:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    checkIfAlreadyFriend();
  }, [currentUserId, loggedInUser]);

  const disabledButtonStyle = {
    opacity: 0.5,
  };

  return (
    <ScrollView style={styles.container}>
      <SafeAreaView>
        <View style={{ height: imageSize, position: "relative" }}>
          <Image
            source={
              user.avatar ===
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                ? imageGenre
                : user.avatar
            }
            style={styles.profilePhoto}
          />
          {canEdit && (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("Edit Profile");
              }}
              style={styles.editBtn}
            >
              <Image source={Edit} style={styles.editImg} />
              <Text style={styles.editBtnText}>{editProfile.title}</Text>
            </TouchableOpacity>
          )}
        </View>

        <View style={styles.actionProfile}>
          {/* 🇫🇷  Ci-dessous , element pour voir si l'utilisateur a été organisateur et sa fiabilité (reliability)*/}
          {/* 🇬🇧 Below, element to see if the user was an organizer and its reliability (reliability)*/}
          {/* <View style={styles.headerRow}>
            <View>
              <Text style={styles.text}>Organizer : never</Text>
            </View>
            <View style={styles.hRow2}>
              <Text style={styles.text}>Reliability : </Text>
              <View style={{ height: 10 }}>
                <Bar
                  style={{ marginTop: 6 }}
                  progress={0.8}
                  color="green"
                  height={12}
                  unfilledColor="red"
                  borderWidth={0}
                />
              </View>
            </View>
          </View> */}
          {/*🇫🇷 Points Container , pour mettre en place system de points*/}
          {/*🇬🇧 Points Container , pour put in on system points*/}
          {/* <View style={styles.pointsContainer}>
            <View>
              <View style={styles.medalCtn}>insert image</View>
              <Text style={styles.userPoint}>9000 {profile.points}</Text>
            </View>
          </View> */}

          <View style={styles.infoText}>
            <Text style={styles.userPseudo}>{user.firstName}</Text>
            {/*🇫🇷 La variable profile.years dans fr.json permet d'afficher "ans", il manque envoyer depuis backend l'age de l'utilisateur*/}
            {/*🇬🇧 The en.json variable profile.yearsl displays "years", it misses send from backend the age of the user*/}
            {/* {age && (
              <Text style={styles.userAge}>
                {age} {profile.years}
              </Text>
            )}
            {age === null || isNaN(age) || age === 0 ? null : (
              <Text style={styles.userAge}>
                {age} {profile.years}
              </Text>
            )} */}
            {/*🇫🇷 La variable profile.usercity dans fr.json permet d'afficher la ville de residence utilisateur,elle est modifie avec de methodes pour le mettre en bon format*/}
            {/*🇬🇧 The variable profile.usercity in en.json allows to display the user's city of residence, it is modified with methods to put it in the right format*/}
            <View style={{ flexDirection: "row" }}>
              <Image source={Placeholder2} style={styles.placeHolderImg} />
              <Text style={styles.userPoint}>{city}</Text>
              {/*🇫🇷 La variable profile.points dans fr.json permet d'afficher "points", pour le sisteme de qualification de l'aplication*/}
              {/*🇬🇧 The en.json variable profile.points displays "points" , for the system qualification app*/}
            </View>
          </View>
          {/*🇫🇷 IMAGE COMMING SOONr*/}
          {/* <View style={{ flex: 1, marginTop: 25, marginHorizontal: 15 }}>
          <img src={ComingSoon} alt="" width="90%" height="auto" />
          </View>
          {/*🇫🇷 BARRE D'OUTILS POUR AJOUTER-BLOQUER-CHAT: A voir si on l'utilise pour version webview*/}
          {!canEdit && (
            <View style={styles.btnContainer}>
              {/*---------------------------🇫🇷 Bouton pour ajouter un utilisateur en ami ------------------------------*/}
              {!alreadyFriend && (
                <TouchableOpacity
                  style={[
                    styles.addFriend,
                    isLoading ? disabledButtonStyle : null,
                  ]}
                  onPress={handlePress}
                  disabled={isLoading}
                >
                  <img src={AddFriend} />
                  <Text
                    style={{
                      paddingLeft: 5,
                      display: width > 350 ? "flex" : "none",
                    }}
                  >
                    {profile.addFriend}
                  </Text>
                </TouchableOpacity>
              )}
              {/* le modal principale avec les options pour ajouter un ami */}
              <Modal
                animationType="slide"
                transparent={true}
                visible={addFriendModalVisible}
                onRequestClose={() => {
                  setAddFriendModalVisible(!addFriendModalVisible);
                }}
              >
                <View style={styles.modalContainerAddFreind}>
                  <View style={[styles.addFriendModalBox, styles.modalAddUser]}>
                    <TouchableOpacity
                      style={styles.closeButton}
                      onPress={() => setAddFriendModalVisible(false)}
                    >
                      <Image source={close} style={styles.closeIcon} />
                    </TouchableOpacity>
                    <Text
                      style={[
                        styles.addFriendModalText,
                        { textAlign: "center" },
                      ]}
                    >
                      {confirmfriendship.jsonpopupconfirmfriendship}
                    </Text>
                    <View
                      style={[
                        styles.addFriendButtonContainer,
                        { alignItems: "center" },
                      ]}
                    >
                      <View style={styles.buttonRow}>
                        <TouchableOpacity
                          style={[
                            styles.addFriendButton,
                            emailPressed && styles.buttonPressed,
                          ]}
                          onPress={() => {
                            setEmailPressed(true);
                            setLastNamePressed(false);
                            setPhonePressed(false);
                            setBirthdayPressed(false);
                            setMemberNumberPressed(false);
                            setButtonPressed(true);
                            //setConfirmEmailModalVisible(true);
                          }}
                        >
                          <View style={styles.iconWrapper}>
                            <Image
                              source={email}
                              style={styles.addFriendButtonIcon}
                            />
                          </View>
                          {/* Email */}
                          <Text
                            style={[
                              styles.addFriendButtonText,
                              emailPressed && styles.buttonTextPressed,
                            ]}
                          >
                            {editProfile.step2.email}
                          </Text>
                        </TouchableOpacity>
                        {isPhonePublic && (
                          <TouchableOpacity
                            style={[
                              styles.addFriendButton,
                              phonePressed && styles.buttonPressed,
                              !isPhoneAvailable && styles.buttonDisabled,
                            ]}
                            onPress={() => {
                              setPhonePressed(true);
                              setEmailPressed(false);
                              setLastNamePressed(false);
                              setBirthdayPressed(false);
                              setMemberNumberPressed(false);
                              setButtonPressed(true);
                            }}
                            disabled={!isPhoneAvailable}
                          >
                            <View style={styles.iconWrapper}>
                              <Image
                                source={phone}
                                style={styles.addFriendButtonIcon}
                              />
                            </View>
                            {/* Phone */}
                            <Text
                              style={[
                                styles.addFriendButtonText,
                                phonePressed && styles.buttonTextPressed,
                                !isPhoneAvailable && styles.buttonDisabledText,
                              ]}
                            >
                              {confirmfriendship.jsonbuttonphone}
                            </Text>
                          </TouchableOpacity>
                        )}
                        {isLastNamePublic && (
                          <TouchableOpacity
                            style={[
                              styles.addFriendButton,
                              lastNamePressed && styles.buttonPressed,
                            ]}
                            onPress={() => {
                              setLastNamePressed(true);
                              setEmailPressed(false);
                              setPhonePressed(false);
                              setBirthdayPressed(false);
                              setMemberNumberPressed(false);
                              setButtonPressed(true);
                            }}
                          >
                            <View style={styles.iconWrapper}>
                              <Image
                                source={lastName}
                                style={styles.addFriendButtonIcon}
                              />
                            </View>
                            {/* Last name */}
                            <Text
                              style={[
                                styles.addFriendButtonText,
                                lastNamePressed && styles.buttonTextPressed,
                              ]}
                            >
                              {createProfile.lastName}
                            </Text>
                          </TouchableOpacity>
                        )}
                      </View>
                      <View style={styles.buttonRow}>
                        {isBirthdayPublic && (
                          <TouchableOpacity
                            style={[
                              styles.addFriendButton,
                              birthdayPressed && styles.buttonPressed,
                            ]}
                            onPress={() => {
                              setBirthdayPressed(true);
                              setEmailPressed(false);
                              setLastNamePressed(false);
                              setPhonePressed(false);
                              setMemberNumberPressed(false);
                              setButtonPressed(true);
                            }}
                          >
                            <View style={styles.iconWrapper}>
                              <Image
                                source={birthday}
                                style={styles.addFriendButtonIcon}
                              />
                            </View>
                            {/* Birthday */}
                            <Text
                              style={[
                                styles.addFriendButtonText,
                                birthdayPressed && styles.buttonTextPressed,
                              ]}
                            >
                              {editProfile.step2.birthday}
                            </Text>
                          </TouchableOpacity>
                        )}
                        <TouchableOpacity
                          style={[
                            styles.addFriendButton,
                            memberNumberPressed && styles.buttonPressed,
                          ]}
                          onPress={() => {
                            setMemberNumberPressed(true);
                            setLastNamePressed(false);
                            setEmailPressed(false);
                            setPhonePressed(false);
                            setBirthdayPressed(false);
                            setButtonPressed(true);
                          }}
                        >
                          <View style={styles.iconWrapper}>
                            <Image
                              source={users}
                              style={styles.addFriendMemberButtonIcon}
                            />
                          </View>
                          {/* Member number */}
                          <Text
                            style={[
                              styles.addFriendMemberText,
                              memberNumberPressed && styles.buttonTextPressed,
                            ]}
                          >
                            {confirmfriendship.jsonbuttonmembernumber}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View style={{ margin: 10 }}></View>
                    <View style={styles.continueBtnView}>
                      {/*--------------------------- Boutton Continuer ------------------------ */}
                      <TouchableOpacity
                        style={[
                          styles.continueButton,
                          continuePressed && styles.buttonPressed,
                          !buttonPressed && styles.buttonDisabled,
                        ]}
                        onPressIn={() => {
                          if (buttonPressed) {
                            setContinuePressed(true);
                          }
                        }}
                        onPressOut={() => {
                          if (buttonPressed) {
                            setContinuePressed(false);
                            setEmailPressed(false);
                            setLastNamePressed(false);
                            setPhonePressed(false);
                            setBirthdayPressed(false);
                            setMemberNumberPressed(false);
                            setButtonPressed(false);
                            if (emailPressed) {
                              setConfirmEmailModalVisible(true); // Ouvrir le deuxième modal
                              setAddFriendModalVisible(false); // Fermer le premier modal
                            } else if (phonePressed) {
                              setPhoneModalVisible(true); // Ouvrir le modal du téléphone
                              setAddFriendModalVisible(false); // Fermer le premier modal
                            } else if (lastNamePressed) {
                              setLastNameModalVisible(true); // Ouvrir le modal du nom de famille
                              setAddFriendModalVisible(false); // Fermer le premier modal
                            } else if (birthdayPressed) {
                              setBirthdayModalVisible(true); // Ouvrir le modal de la date de naissance
                              setAddFriendModalVisible(false); // Fermer le premier modal
                            } else if (memberNumberPressed) {
                              setMemberNumberModalVisible(true); // Ouvrir le modal du numéro de membre
                              setAddFriendModalVisible(false); // Fermer le premier modal
                            } else {
                              setAddFriendModalVisible(false); // Fermer le premier modal
                            }
                          }
                        }}
                      >
                        <View style={styles.addFriendView}>
                          <Text
                            style={[
                              styles.addFriendButtonText,
                              continuePressed && styles.buttonTextPressed,
                            ]}
                          >
                            {editProfile.step2.continue}
                          </Text>
                          <Image
                            source={ArrayRight}
                            style={styles.addFriendButtonIcon}
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </Modal>
              {/* modal ajout d'ami par email */}
              <Modal
                animationType="slide"
                transparent={true}
                visible={confirmEmailModalVisible}
                onRequestClose={() => {
                  setConfirmEmailModalVisible(false);
                }}
              >
                <View style={styles.centeredView}>
                  <View style={styles.confirmEmailModalContainer}>
                    <TouchableOpacity
                      style={styles.backButtonWrapper}
                      onPress={() => {
                        setConfirmEmailModalVisible(false);
                        setAddFriendModalVisible(true);
                      }}
                    >
                      <Image source={leftArrow} style={styles.leftArrowIcon} />
                    </TouchableOpacity>
                    <View style={styles.iconWrapper}>
                      <Image
                        source={email}
                        style={styles.addFriendButtonIcon}
                      />
                    </View>
                    <Text style={styles.confirmEmailModalText}>
                      {confirmfriendship.jsonpopupenteremail}
                    </Text>
                    <TextInput
                      style={styles.emailInput}
                      placeholder={confirmfriendship.jsonplaceholderemail}
                      placeholderTextColor="gray"
                      value={friendEmail}
                      onChangeText={(text) => setFriendEmail(text)}
                    />
                    <TouchableOpacity
                      style={styles.confirmEmailButton}
                      onPress={async () => {
                        await handleAddFriend(friendEmail, "email");
                        setConfirmEmailModalVisible(false);
                        setModalVisible(false);
                      }}
                    >
                      <Text style={styles.confirmEmailButtonText}>
                        {confirmfriendship.jsonbuttonconfirm}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
              <PopupDialog
                isShown={popupDialogVisible}
                dialogMessage={popupDialogContent}
                onDismiss={() => setPopupDialogVisible(false)}
              />
              <TouchableOpacity
                onPress={() => setPhoneModalVisible(true)}
              ></TouchableOpacity>
              {/* modal ajout d'ami par téléphone */}
              <Modal
                animationType="slide"
                transparent={true}
                visible={phoneModalVisible}
                onRequestClose={() => {
                  setPhoneModalVisible(false);
                }}
              >
                <View style={styles.centeredView}>
                  <View style={styles.confirmEmailModalContainer}>
                    <TouchableOpacity
                      style={styles.backButtonWrapper}
                      onPress={() => {
                        setPhoneModalVisible(false);
                        setAddFriendModalVisible(true);
                      }}
                    >
                      <Image source={leftArrow} style={styles.leftArrowIcon} />
                    </TouchableOpacity>
                    <View style={styles.iconWrapper}>
                      <Image
                        source={phone}
                        style={styles.addFriendButtonIcon}
                      />
                    </View>
                    <Text style={styles.confirmEmailModalText}>
                      {confirmfriendship.jsonpopupenterphonenumber}
                    </Text>

                    <View style={styles.phoneInputContainer}>
                      <IntlPhoneInput
                        containerStyle={styles.phoneInput}
                        value={friendPhone}
                        onChangeText={(number) => {
                          setFriendPhone(number);
                          setInputEmpty(!number);
                        }}
                        defaultCountry="FR"
                        placeholder={confirmfriendship.jsonplaceholdertelephone}
                        placeholderTextColor="#808080"
                        style={isInputEmpty ? { fontStyle: "italic" } : {}}
                        flagStyle={styles.countryFlag}
                        textComponent={({ value }) => (
                          <View style={styles.countryTextContainer}>
                            <Text style={styles.countryText}>{value}</Text>
                          </View>
                        )}
                      />
                    </View>
                    <TouchableOpacity
                      style={styles.confirmLastNameButton}
                      onPress={async () => {
                        await handleAddFriend(
                          friendPhone.unmaskedPhoneNumber,
                          "phoneNumber"
                        );
                        setLastNameModalVisible(false); // Ferme le modal de l'e-mail
                        setModalVisible(false); // Ferme le premier modal
                      }}
                    >
                      <Text style={styles.confirmLastNameButtonText}>
                        {confirmfriendship.jsonbuttonconfirm}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
              <PopupDialog
                isShown={popupDialogVisible}
                dialogMessage={popupDialogContent}
                onDismiss={() => setPopupDialogVisible(false)}
              />
              {/* modal ajout d'ami par nom de famille */}
              <Modal
                animationType="slide"
                transparent={true}
                visible={lastNameModalVisible}
                onRequestClose={() => {
                  setLastNameModalVisible(false);
                }}
              >
                <View style={styles.centeredView}>
                  <View style={styles.confirmEmailModalContainer}>
                    <TouchableOpacity
                      style={styles.backButtonWrapper}
                      onPress={() => {
                        setLastNameModalVisible(false);
                        setAddFriendModalVisible(true);
                      }}
                    >
                      <Image source={leftArrow} style={styles.leftArrowIcon} />
                    </TouchableOpacity>
                    <View style={styles.iconWrapper}>
                      <Image
                        source={lastName}
                        style={styles.addFriendButtonIcon}
                      />
                    </View>
                    <Text style={styles.confirmEmailModalText}>
                      {confirmfriendship.jsonpopupenterlastname}
                    </Text>
                    <TextInput
                      style={styles.lastNameInput}
                      placeholder={confirmfriendship.jsonplaceholderlastname}
                      placeholderTextColor="gray"
                      value={friendLastName}
                      onChangeText={(text) => setFriendLastName(text)}

                      // Ajoutez ici les autres propriétés pour gérer la valeur du champ de saisie
                    />
                    <TouchableOpacity
                      style={styles.confirmLastNameButton}
                      onPress={async () => {
                        await handleAddFriend(friendLastName, "lastName"); // TODO incorrect a modifier
                        setLastNameModalVisible(false); // Ferme le modal de l'e-mail
                        setModalVisible(false); // Ferme le premier modal
                      }}
                    >
                      <Text style={styles.confirmLastNameButtonText}>
                        {confirmfriendship.jsonbuttonconfirm}
                      </Text>
                    </TouchableOpacity>
                    {/* Contenu du modal du nom de famille */}
                  </View>
                </View>
              </Modal>
              <PopupDialog
                isShown={popupDialogVisible}
                dialogMessage={popupDialogContent}
                onDismiss={() => setPopupDialogVisible(false)}
              />
              {/* modal ajout d'ami par date de naissance */}

              <Modal
                animationType="slide"
                transparent={true}
                visible={birthdayModalVisible}
                onRequestClose={() => {
                  setBirthdayModalVisible(false);
                }}
              >
                <View style={styles.centeredView}>
                  <View style={styles.confirmEmailModalContainer}>
                    <TouchableOpacity
                      style={styles.backButtonWrapper}
                      onPress={() => {
                        setBirthdayModalVisible(false);
                        setAddFriendModalVisible(true);
                      }}
                    >
                      <Image source={leftArrow} style={styles.leftArrowIcon} />
                    </TouchableOpacity>
                    <View style={styles.iconWrapper}>
                      <Image
                        source={birthday}
                        style={styles.addFriendButtonIcon}
                      />
                    </View>
                    <Text style={styles.confirmEmailModalText}>
                      {confirmfriendship.jsonpopupenterbirthday}
                    </Text>

                    {/* Utilisation du TextInput pour une saisie manuelle */}
                    <View style={styles.centeredContainer}>
                      <TextInput
                        style={styles.dateInput} // Vous devez définir ce style dans votre fichier styles
                        placeholder="DD/MM/YYYY"
                        value={friendBirthday}
                        onChangeText={setFriendBirthday}
                      />
                    </View>

                    <TouchableOpacity
                      style={styles.confirmBirthdayButton}
                      onPress={async () => {
                        await handleAddFriend(friendBirthday, "birthday");
                        setBirthdayModalVisible(false);
                        setModalVisible(false);
                      }}
                    >
                      <Text style={styles.confirmBirthdayButtonText}>
                        {confirmfriendship.jsonbuttonconfirm}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
              <PopupDialog
                isShown={popupDialogVisible}
                dialogMessage={popupDialogContent}
                onDismiss={() => setPopupDialogVisible(false)}
              />
              {/* modal ajout d'ami par numéro de membre */}
              <Modal
                animationType="slide"
                transparent={true}
                visible={memberNumberModalVisible}
                onRequestClose={() => {
                  setMemberNumberModalVisible(false);
                }}
              >
                <View style={styles.centeredView}>
                  <View style={styles.confirmEmailModalContainer}>
                    <TouchableOpacity
                      style={styles.backButtonWrapper}
                      onPress={() => {
                        setMemberNumberModalVisible(false);
                        setAddFriendModalVisible(true);
                      }}
                    >
                      <Image source={leftArrow} style={styles.leftArrowIcon} />
                    </TouchableOpacity>
                    <View style={styles.iconWrapper}>
                      {/*

                     */}
                      <Image
                        source={users}
                        style={{
                          width: 48,
                          height: 48,
                          resizeMode: "contain",
                        }}
                      />
                    </View>
                    <Text style={styles.confirmEmailModalText}>
                      {confirmfriendship.jsonpopupentermembernumber}
                    </Text>

                    {/*
                     logique poour recuperer le users
                   */}
                    <View style={styles.phoneInputContainer}>
                      <TextInput
                        style={styles.phoneInput}
                        value={friendMemberNumber}
                        onChangeText={(number) => {
                          setFriendMemberNumber(number);
                        }}
                        placeholder={
                          confirmfriendship.jsonplaceholdermembernumber
                        }
                      />
                    </View>
                    <TouchableOpacity
                      style={styles.confirmLastNameButton}
                      onPress={async () => {
                        await handleAddFriend(friendMemberNumber, "memberId"); // TODO incorrect a modifier
                        setLastNameModalVisible(false); // Ferme le modal de l'e-mail
                        setModalVisible(false); // Ferme le premier modal
                      }}
                    >
                      <Text style={styles.confirmLastNameButtonText}>
                        {confirmfriendship.jsonbuttonconfirm}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
              <PopupDialog
                isShown={popupDialogVisible}
                dialogMessage={popupDialogContent}
                onDismiss={() => setPopupDialogVisible(false)}
              />
              {/*---------------------------🇫🇷 Bouton pour tchat------------------------- ------------------------------*/}
              {/*🇫🇷 La variable profile.chatdans fr.json permet d'afficher "Chat"*/}
              {/*🇬🇧 The en.json variable profile.chat displays "Chat"*/}
              {/* <TouchableOpacity style={styles.chat}>
                <img src={Chat} />
                <Text
                  style={{
                    paddingLeft: 5,
                    display: width > 350 ? "flex" : "none",
                  }}
                >
                  {profile.chat}
                </Text>
              </TouchableOpacity> */}
              {/*🇫🇷 La variable profile.block}dans fr.json permet d'afficher "Bloquer"*/}
              {/*🇬🇧 The en.json variable profile.block} displays "Block"*/}

              {/*---------------------------🇫🇷 Bouton pour Bloquer ------------------------------*/}
              {/* <TouchableOpacity
                style={styles.block}
                onPress={() => setModalVisible(true)}
              >
                <img src={BlockFriend} />
                <Text
                  style={{
                    paddingLeft: 5,
                    display: width > 350 ? "flex" : "none",
                  }}
                >
                  {profile.block}
                </Text>
              </TouchableOpacity> */}

              <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                  setModalVisible(!modalVisible);
                }}
              >
                <View style={styles.modalContainer}>
                  <View style={styles.popupBox}>
                    <Image source={warning} style={styles.modalImage} />
                    <Text style={styles.modalText}>
                      If you continue, this user will not be able to see you as
                      an attendee or to see your activities.
                    </Text>
                    <View style={styles.buttonContainer}>
                      <TouchableOpacity
                        style={[
                          styles.confirmButton,
                          confirmPressed && styles.buttonPressed,
                        ]}
                        onPressIn={() => setConfirmPressed(true)}
                        onPressOut={() => {
                          setConfirmPressed(false);
                          // Ajoutez ici la logique pour bloquer l'utilisateur
                          setModalVisible(false);
                        }}
                      >
                        <Text
                          style={[
                            styles.buttonText,
                            confirmPressed && styles.buttonTextPressed,
                          ]}
                        >
                          {confirmfriendship.jsonbuttonconfirm}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.cancelButton,
                          cancelPressed && styles.buttonPressed,
                        ]}
                        onPressIn={() => setCancelPressed(true)}
                        onPressOut={() => {
                          setCancelPressed(false);
                          setModalVisible(false);
                        }}
                      >
                        <Text
                          style={[
                            styles.buttonText,
                            cancelPressed && styles.buttonTextPressed,
                          ]}
                        >
                          {activity.cancel}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </Modal>
            </View>
          )}
        </View>

        {/*🇫🇷 menu de navigation crée pour switcher entre les pages information, activités et friends de l'utilisateur*/}
        {/*🇬🇧 navigation menu created to switch between the user's information, activities and friends pages*/}
        {/*🇫🇷 les functionalités activites et reseau sont en off pour l'instant*/}
        {/*🇬🇧 the activities and network function are off for now*/}

        <TopNavigation
          arg={[
            //🇫🇷 c'est le menu de navigation qui permet de switcher entre les pages information, activités et friends de l'utilisateur*/}
            {
              link: profile.info,
              to: (props) => (
                <ProfileInfo {...props} user={user} scr={scr} city={city} />
              ),
            },
            //c'est le menu de navigation qui permet de switcher entre les pages information, activités et Network de l'utilisateur*/}
            {
              link: profile.activities,
              to: (props) => <ProfileActivities {...props} userId={user._id} />,
            },
            // //c'est le menu de navigation qui permet de switcher entre les pages information, activités et Network de l'utilisateur*/}
            {
              link: profile.network,
              to: (props) => <ProfileNetwork {...props} userId={user._id} />,
            },
          ]}
        />
      </SafeAreaView>
    </ScrollView>
  );
};

export default MyProfileScreen;
