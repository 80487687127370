import React, { useState } from "react";
import { View, Picker, StyleSheet } from "react-native";

const SimpleDateField = ({ setDate }) => {
  const [day, setDay] = useState("01");
  const [month, setMonth] = useState("01");
  const [year, setYear] = useState("2000");

  const handleDateChange = () => {
    setDate(`${day}/${month}/${year}`);
  };

  return (
    <View style={styles.centeredContainer}>
      <View style={styles.dateContainer}>
        <Picker
          selectedValue={day}
          style={styles.picker}
          onValueChange={(itemValue, itemIndex) => {
            setDay(itemValue);
            handleDateChange();
          }}
        >
          {[...Array(31)].map((_, i) => (
            <Picker.Item
              key={i}
              label={String(i + 1).padStart(2, "0")}
              value={String(i + 1).padStart(2, "0")}
            />
          ))}
        </Picker>

        <Picker
          selectedValue={month}
          style={styles.picker}
          onValueChange={(itemValue, itemIndex) => {
            setMonth(itemValue);
            handleDateChange();
          }}
        >
          {[...Array(12)].map((_, i) => (
            <Picker.Item
              key={i}
              label={String(i + 1).padStart(2, "0")}
              value={String(i + 1).padStart(2, "0")}
            />
          ))}
        </Picker>

        <Picker
          selectedValue={year}
          style={styles.picker}
          onValueChange={(itemValue, itemIndex) => {
            setYear(itemValue);
            handleDateChange();
          }}
        >
          {[...Array(101)].map((_, i) => (
            <Picker.Item
              key={i}
              label={String(2023 - i)}
              value={String(2023 - i)}
            />
          ))}
        </Picker>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredContainer: {
    flex: 1,
    alignItems: "center",
    marginRight: 100,
  },
  dateContainer: {
    flexDirection: "row",
    maxWidth: 200,
  },
  picker: {
    width: 100,
  },
});

export default SimpleDateField;