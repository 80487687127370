import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  profil: {
    backgroundColor: "white",
    flex: 1,
  },
  gender: {
    marginTop: 60,
    alignItems: "center",
    marginHorizontal: 25,
  },
  account: {
    marginTop: 25,
    alignItems: "center",
    marginHorizontal: 25,
  },
  btn: {
    marginTop: 100,
    alignItems: "center",
  },
});

export default styles;
