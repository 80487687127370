import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  ActivityIndicator,
  Image,
  TouchableOpacity,
} from "react-native";
import axios from "axios";
import { hostname } from "../../../../RN-mobile/backendconnect/hostname";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

function NetworkProfile({ userId }) {
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigation = useNavigation();

   const fetchParticipants = async () => {
     try {
       const userToken = await AsyncStorage.getItem("userToken");
       console.log("userToken:", userToken);
       console.log("userId:", userId);

       if (!userToken || !userId) {
         setError(
           "Vous devez vous connecter pour voir la liste des participants."
         );
         setLoading(false);
         return;
       }

       // Récupérez toutes les activités de l'utilisateur avec la nouvelle route
       const response = await axios.get(
         `${hostname}/api/v1/user/${userId}/activities`,
         {
           headers: {
             Authorization: `Bearer ${userToken}`,
           },
         }
       );

       const allActivities = response.data;
       if (!allActivities || allActivities.length === 0) {
         setError("Aucune activité trouvée pour cet utilisateur.");
         setLoading(false);
         return;
       }

       // Filtrer tous les participants à l'exception de l'utilisateur actuel
       let allAttendees = allActivities
         .map((activity) => {
           // Assurez-vous que chaque participant est un objet avec un _id
           if (
             activity.attendees &&
             activity.attendees.length > 0 &&
             typeof activity.attendees[0] === "object"
           ) {
             return activity.attendees
               .map((att) => att._id.toString()) // Convert ObjectId to string
               .filter((id) => id !== userId);
           }
           return activity.attendees;
         })
         .flat();

       const uniqueAttendees = [...new Set(allAttendees)];

       // Récupérez les informations sur les participants
       const attendeeInfos = await Promise.all(
         uniqueAttendees.map(async (attendeeId) => {
           console.log(`Fetching info for attendee: ${attendeeId}`);
           const {
             data: { user },
           } = await axios.get(
             `${hostname}/api/v1/user/getuserinfo/${attendeeId}`,
             {
               headers: {
                 Authorization: `Bearer ${userToken}`,
               },
             }
           );
           return user;
         })
       );

       setParticipants(attendeeInfos);
     } catch (err) {
       console.error("Erreur lors de la récupération des participants :", err);
     } finally {
       setLoading(false);
     }
   };

  useEffect(() => {
    fetchParticipants();
  }, []);

  const ParticipantItem = ({ item, column }) => {
    const getNameColor = (sexe) => {
      switch (sexe) {
        case "male":
          return "#001AFF";
        case "female":
          return "#FF13CB";
        default:
          return "gray"; // Couleur par défaut si le sexe n'est pas défini ou autre
      }
    };

    return (

      <TouchableOpacity
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
        }}
        onPress={() => {
          navigation.navigate("Profile", { user: item });
        }}
      >
        {/* Avatar */}
        {item.avatar ? (
          <Image
            source={{ uri: item.avatar }}
            style={{ width: 50, height: 50, borderRadius: 25 }}
          />
        ) : (
          <View
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              backgroundColor: "gray",
            }}
          />
        )}

        {/* Name */}
        <Text
          style={{
            fontWeight: "bold",
            textAlign: "center",
            marginTop: 5,
            fontSize: 10,
            color: getNameColor(item.sexe),
          }}
        >
          {item.userName?.charAt(0)?.toUpperCase()+ item.userName?.slice(1).toLowerCase()}
          {/* {item.lastName?.charAt(0)?.toUpperCase() +
            item.lastName?.slice(1).toLowerCase()} */}
        </Text>

        {/* Location */}
        {/* <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 5 }}
        >
          <Text>
            📍 {item.city}, {item.postalCode}
          </Text>
        </View> */}
      </TouchableOpacity>
    );
  };
  if (loading) return <ActivityIndicator size="large" color="#0000ff" />;
  if (error) return <Text>Error: {error}</Text>;

  return (
    <View style={{ flex: 1, paddingTop: 20 }}>
      {/* Ajout du titre "Network" et du nombre total de participants */}
      <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10 }}>
        Network{" "}
        <text style={{ color: "#59C09B" }}>{participants.length}</text>
      </Text>

      <FlatList
        data={participants}
        keyExtractor={(item) => item._id}
        renderItem={({ item, index }) => (
          <ParticipantItem item={item} column={index % 4} />
        )}
        numColumns={4}
        ItemSeparatorComponent={() => (
          <View
          // style={{ height: 1, backgroundColor: "gray", marginVertical: 10 }}
          />
        )}
      />
    </View>
  );
}

export default NetworkProfile;