import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  TextInput,
} from "react-native";
import styles from "../Styles/MembersScreenCss";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import MembersSquare from "../../components/MembersSquare";
import { hostname } from "../../../../RN-mobile/backendconnect/hostname";
import Male from "../../assets/images/male.svg";
import Female from "../../assets/images/female.svg";
import Settings from "../../assets/images/searchOff.svg";
import { Image } from "react-native";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import { ager } from "../../../utils/functionDate.js";
import JSON from "../../../../RN-mobile/assets/json/en.json";
import FriendList from "./FriendList";
import Network from "./Network";
const { menu, members, bottomNav, maps } = JSON;

const MembersScreen = (props) => {
  console.log(
    "membersScreen props.scrbottomNav.members.placeHolder.:",
    props.scr.bottomNav.members.placeHolder
  );
  console.log(
    "membersScreen props.scr.props.scr.bottomNav:",
    props.scr.bottomNav
  );
  const [membersList, setMembersList] = useState([]);
  const [activeButton, setActiveButton] = useState("Members");
  const [searchValue, setSearchValue] = useState("");
  const [skip, setSkip] = useState(0);
  const [chargement, setChargement] = useState(true); // pour arreter le chargement lorsqu'il n'y a pas plus de données
  const limit = 20;
  const [subFilter, setSubFilter] = useState("");

  const navigation = useNavigation();

  useFocusEffect(
    React.useCallback(() => {
      if (!chargement) return;

      const fetchData = async () => {
        let apiUrl;
        if (activeButton === "Network") {
          apiUrl = `${hostname}/api/v1/someOtherEndpoint`; // Mettez ici l'URL pour récupérer les réseaux
        } else if (activeButton === "FriendList") {
          apiUrl = `${hostname}/api/v1/user/getfriendlist?limit=${limit}&skip=${skip}`;
        } else {
          apiUrl = `${hostname}/api/v1/user/getuserlist?limit=${limit}&skip=${skip}`;
        }

        try {
          const response = await axios.get(apiUrl);
          const shuffledData = response.data;
          console.log(shuffledData);

          if (shuffledData.length === 0) {
            setChargement(false);
          } else {
            if (activeButton === "Members") {
              setMembersList((prevEvents) => [...prevEvents, ...shuffledData]);
            } else {
              // Traitement pour d'autres boutons si nécessaire
            }
          }
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchData();

      if (activeButton === "Members") {
        // N'augmentez le 'skip' que si vous êtes dans l'onglet 'Members'
        const timer = setInterval(() => {
          setSkip((prevSkip) => prevSkip + limit);
        }, 1500);

        return () => {
          clearInterval(timer);
        };
      }
    }, [skip, chargement, activeButton])
  );

  console.log(
    "MembersScreen t2022_selectLanguage:",
    props.scr.home.t2022_selectLanguage
  );
  console.log("MembersScreen membersList:", membersList.length);

  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () => {
      setSkip((prevSkip) => prevSkip + limit); // Reset skip to 0
    });
    // The cleanup function will run when the component is unmounted
    return unsubscribe;
  }, []);

  return (
    <>
      <View style={styles.menuContainer}>
        <TouchableOpacity
          style={[
            styles.button,
            activeButton === "Members" && { backgroundColor: "#59c09b" },
          ]}
          onPress={() => setActiveButton("Members")}
        >
          <Text style={styles.text}>{props.scr.bottomNav.members.title}</Text>
        </TouchableOpacity>

        <View style={styles.separator} />

        <TouchableOpacity
          style={[
            styles.button,
            activeButton === "Network" && { backgroundColor: "#59c09b" },
          ]}
          onPress={() => {
            setActiveButton("Network");
          }}
        >
          <Text style={styles.text}>Network</Text>
        </TouchableOpacity>

        <View style={styles.separator} />

        <TouchableOpacity
          style={[
            styles.button,
            activeButton === "FriendList" && { backgroundColor: "#59c09b" },
          ]}
          onPress={() => {
            setActiveButton("FriendList");
          }}
        >
          <Text style={styles.text}>FriendList</Text>
        </TouchableOpacity>
      </View>
      {activeButton === "Members" && (
        <View style={styles.secondaryMenuContainer}>
          <TouchableOpacity
            style={[
              styles.smallButton,
              subFilter === "Male" && { backgroundColor: "#59c09b" },
            ]}
            onPress={() => setSubFilter("Male")}
          >
            <Image source={Male} style={{ width: 21, height: 21 }} />
          </TouchableOpacity>

          <View style={styles.smallSeparator} />

          <TouchableOpacity
            style={[
              styles.smallButton,
              subFilter === "Pros" && { backgroundColor: "#59c09b" },
            ]}
            onPress={() => setSubFilter("Pros")}
          >
            <Text style={styles.smallText}>Pros</Text>
          </TouchableOpacity>

          <View style={styles.smallSeparator} />

          <TouchableOpacity
            style={[
              styles.smallButton,
              subFilter === "Female" && { backgroundColor: "#59c09b" },
            ]}
            onPress={() => setSubFilter("Female")}
          >
            <Image source={Female} style={{ width: 21, height: 21 }} />
          </TouchableOpacity>
        </View>
      )}
      {activeButton === "Members" && (
        <>
          <View style={styles.searchContainer}>
            <Image source={Settings} style={styles.searchIcon} />
            <TextInput
              style={styles.searchInput}
              // placeholder="Search here ..."
              placeholder={props.scr.bottomNav.members.placeHolder}
              placeholderTextColor="#CCCCCC"
              onChangeText={(text) => setSearchValue(text)}
            />
          </View>

          {membersList.length > 0 && (
            <ScrollView>
              <View style={styles.container}>
                {membersList
                  .filter((member) => {
                    if (subFilter === "Male") {
                      return (
                        member.sexe === "male" &&
                        member.isPersonalAccount &&
                        (!searchValue ||
                          (member.firstName &&
                            member.firstName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())))
                      );
                    }
                    if (subFilter === "Pros") {
                      return (
                        !member.isPersonalAccount &&
                        (!searchValue ||
                          (member.firstName &&
                            member.firstName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())))
                      );
                    }
                    if (subFilter === "Female") {
                      return (
                        member.sexe === "female" &&
                        member.isPersonalAccount &&
                        (!searchValue ||
                          (member.firstName &&
                            member.firstName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())))
                      );
                    }
                    if (searchValue !== "") {
                      return (
                        member.firstName &&
                        member.firstName
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      );
                    }
                    return true; // cette ligne affichera tous les membres par défaut
                  })
                  .map((member, index) => (
                    <View key={index} style={styles.memberCard}>
                      <MembersSquare
                        city={member.city}
                        age={ager(member.birthday)}
                        name={member.firstName}
                        avatar={member.avatar}
                        member={member}
                        scr={props.scr}
                      />
                    </View>
                  ))}
              </View>
            </ScrollView>
          )}
        </>
      )}
      {activeButton === "Network" && <Network />}

      {activeButton === "FriendList" && <FriendList />}
    </>
  );
};

export default MembersScreen;
