import React from "react";
import { Modal, Text, View, TouchableOpacity } from "react-native";

const PopupDialog = ({ isShown, onDismiss, dialogMessage }) => {
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShown}
      onRequestClose={onDismiss}
    >
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <View
          style={{
            width: 300,
            padding: 20,
            backgroundColor: "white",
            borderRadius: 10,
            alignItems: "center",
          }}
        >
          {dialogMessage === "Congratulations ! You are now friends" ? (
            <>
              <Text style={{ fontWeight: 'bold', marginBottom: 10, textAlign: "center" }}>
                Congratulations !
              </Text>
              <Text style={{ fontWeight: 'bold',fontSize:22 , textAlign: "center" }}>
                You are now friends
              </Text>
            </>
          ) : (
            <Text style={{ textAlign: "center" }}>{dialogMessage}</Text>
          )}

          <TouchableOpacity
            onPress={onDismiss}
            style={{
              marginTop: 20,
              backgroundColor: "#59C09B",
              borderRadius: 5,
              paddingHorizontal: 15,
              paddingVertical: 8
            }}
          >
            <Text style={{ color: "white" }}>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default PopupDialog;
