import React, { useState, useEffect } from "react";
import { Text, View, Switch, FlatList, ScrollView, Image } from "react-native";
import styles from "../Styles/Interaction24ECss";
import axios from "axios";
import icon from "../../assets/images/MemberShip_images/Coupes-04.svg";

const THUMB_COLOR = "#f4f3f4";
const TRACK_COLOR = { false: "#767577", true: "#4FBA6F" };

const Interaction24E = () => {
  const [usersData, setUsersData] = useState([]);
  const [isEveryoneEnabled, setEveryoneEnabled] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "/api/user/getuserlist"
        );
        setUsersData(
          response.data.map((user) => ({
            id: user._id,
            avatar: { uri: user.avatar },
            name: user.firstName + " " + user.lastName,
            icon: icon,
            isEnabled: true, // Supposons que par défaut, tous les utilisateurs sont activés
          }))
        );
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    }

    fetchData();
  }, []);

  const toggleIndividualSwitch = (itemId) => {
    setUsersData((prevData) =>
      prevData.map((item) =>
        item.id === itemId ? { ...item, isEnabled: !item.isEnabled } : item
      )
    );
  };

  const toggleEveryoneSwitch = () => {
    const newStatus = !isEveryoneEnabled;
    setEveryoneEnabled(newStatus);
    setUsersData(usersData.map((item) => ({ ...item, isEnabled: newStatus })));
  };

  const renderUserItem = ({ item }) => (
    <View style={styles.itemContainer}>
      <Image source={item.avatar} style={styles.avatar} />
      <Image source={item.icon} style={styles.icon} />
      <Text style={styles.name}>{item.name}</Text>
      <Switch
        trackColor={TRACK_COLOR}
        thumbColor={THUMB_COLOR}
        onValueChange={() => toggleIndividualSwitch(item.id)}
        value={item.isEnabled}
      />
    </View>
  );

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        You organized an activity called “Apero maison”, was everyone present?
      </Text>
      <ScrollView style={styles.scrollView}>
        <View style={styles.switchContainer}>
          <Text style={styles.text1}>Everyone was here</Text>
          <Switch
            trackColor={TRACK_COLOR}
            thumbColor={THUMB_COLOR}
            onValueChange={toggleEveryoneSwitch}
            value={isEveryoneEnabled}
          />
        </View>

        <FlatList
          data={usersData}
          renderItem={renderUserItem}
          keyExtractor={(item) => item.id.toString()}
        />
      </ScrollView>
    </View>
  );
};

export default Interaction24E;
